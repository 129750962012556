exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-features-ab-evaluation-js": () => import("./../../../src/pages/features/ab-evaluation.js" /* webpackChunkName: "component---src-pages-features-ab-evaluation-js" */),
  "component---src-pages-features-audio-video-js": () => import("./../../../src/pages/features/audio-video.js" /* webpackChunkName: "component---src-pages-features-audio-video-js" */),
  "component---src-pages-features-computer-vision-js": () => import("./../../../src/pages/features/computer-vision.js" /* webpackChunkName: "component---src-pages-features-computer-vision-js" */),
  "component---src-pages-features-dependencies-relations-js": () => import("./../../../src/pages/features/dependencies-relations.js" /* webpackChunkName: "component---src-pages-features-dependencies-relations-js" */),
  "component---src-pages-features-large-language-models-js": () => import("./../../../src/pages/features/large-language-models.js" /* webpackChunkName: "component---src-pages-features-large-language-models-js" */),
  "component---src-pages-features-named-entity-recognition-js": () => import("./../../../src/pages/features/named-entity-recognition.js" /* webpackChunkName: "component---src-pages-features-named-entity-recognition-js" */),
  "component---src-pages-features-prompt-engineering-js": () => import("./../../../src/pages/features/prompt-engineering.js" /* webpackChunkName: "component---src-pages-features-prompt-engineering-js" */),
  "component---src-pages-features-span-categorization-js": () => import("./../../../src/pages/features/span-categorization.js" /* webpackChunkName: "component---src-pages-features-span-categorization-js" */),
  "component---src-pages-features-task-routing-js": () => import("./../../../src/pages/features/task-routing.js" /* webpackChunkName: "component---src-pages-features-task-routing-js" */),
  "component---src-pages-features-text-classification-js": () => import("./../../../src/pages/features/text-classification.js" /* webpackChunkName: "component---src-pages-features-text-classification-js" */),
  "component---src-pages-features-training-js": () => import("./../../../src/pages/features/training.js" /* webpackChunkName: "component---src-pages-features-training-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */)
}

