exports.onRouteUpdate = ({ location }) => {
    // Fix anchor links, especially if links are opened in new tab
    if (location.hash) {
        setTimeout(() => {
            const el = document.querySelector(`${location.hash}`)
            if (el) {
                // Navigate to targeted element
                el.scrollIntoView()
                // Force recomputing :target pseudo class with pushState/popState
                window.location.hash = location.hash
            }
        }, 0)
    }
    // The SendOwl script doesn't seem to fire by itself, so we're doing it
    // manually here. We also need to wait for the script to actually load
    // before we can call its methods
    if (location.pathname === '/buy/' || location.pathname === '/buy') {
        const script = document.createElement('script')
        script.src = 'https://transactions.sendowl.com/assets/sendowl.js'
        script.type = 'text/javascript'
        script.addEventListener('load', () => {
            document.querySelectorAll('.sendowl-button').forEach(button => {
                if (window.sendOwl) {
                    window.sendOwl.addClickHandler(button, window.sendOwl.purchaseHandler)
                }
            })
        })
        document.body.appendChild(script)
    }
}
